export const useNotifySubscriberStore = defineStore("notifySubscriber", () => {
    const workerRegistration = ref<ServiceWorkerRegistration | null>(null)
    const subscription = ref<PushSubscription | null>(null)
    const isSubscribed = ref(false)

    return {
        workerRegistration: readonly(workerRegistration),
        isSubscribed: readonly(isSubscribed),
        subscription: readonly(subscription),
        refreshAsync: async (registration: ServiceWorkerRegistration) => {
            workerRegistration.value = registration
            if (Notification.permission !== "granted") {
                return
            }
            subscription.value = await createSubscriptionAsync(registration)
            isSubscribed.value = await sendSubscriptionRequestAsync(subscription.value, "/api/v1/notify/refresh")
        },
        subscribeAsync: async () => {
            if (!!!workerRegistration.value) {
                isSubscribed.value = false
                return false
            }
            if (!await ensureNotificationPermissionAsync()) {
                isSubscribed.value = false
                return
            }
            subscription.value ??= await createSubscriptionAsync(workerRegistration.value)
            if (!!!subscription.value) {
                isSubscribed.value = false
                return false
            }
            isSubscribed.value = await sendSubscriptionRequestAsync(subscription.value, "/api/v1/notify/subscribe")
            if (isSubscribed.value) {
                console.log("Notify subscription added!")
            } else {
                console.error("Could not add notify subscription")
            }
            return isSubscribed.value
        },
        unsubscribeAsync: async () => {
            if (!!!subscription.value) {
                isSubscribed.value = false
                return false
            }
            if (await sendSubscriptionRequestAsync(subscription.value, "/api/v1/notify/unsubscribe")) {
                isSubscribed.value = false
                console.log("Notify subscription removed!")
            } else {
                console.error("Could not remove notify subscription")
            }
            return !isSubscribed.value
        }
    }
})

const ensureNotificationPermissionAsync = async () => {
    const result = await Notification.requestPermission()
    return result == "granted"
}

const createSubscriptionAsync = async (registration: ServiceWorkerRegistration) => await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: "BOd2EQ8LTe3KAgMX9lWwTlHTRzv1Iantw50Mw6pUnsNr3pcxl8iglUs-YlQEQLo4UbJk9oyXs_BxgyAe0TCqKME",
});

const sendSubscriptionRequestAsync = async (subscription: PushSubscription, url: string | URL) => {
    const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(subscription),
        headers: { "Content-Type": "application/json", }
    })
    return response.status >= 200 && response.status <= 299
}
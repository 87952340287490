<template>
    <div class="card card-interactive card-secondary">
        <div class="header-container">
            <span class="header-info">
                <header>
                    <h3>
                        <NuxtLink v-if="!!event" v-bind:to="'/events/' + event.id">
                            {{ event.name }}
                        </NuxtLink>
                        <SkeletonLoader v-else label="Loading event" />
                    </h3>
                </header>
                <DateText v-if="!!event" v-bind:date="event.date" v-slot="dateText">
                    <p>{{ dateText.dateText }}</p>
                </DateText>
                <SkeletonLoader v-else label="Loading event" />
            </span>
            <i class="fa fa-arrow-up-right-from-square" />
        </div>
        <MediaCarousel v-if="!!event && !pending" v-bind:media-ids="event.medias"
            v-bind:no-medias-text="event.description" v-bind:show-no-media-text="true" v-bind:visible-elements="1"
            v-bind:use-previews="usePreviewMedias" />
        <SkeletonLoader v-else label="Loading event medias" />
    </div>
</template>

<script setup lang="ts">
import type { EventModel } from '~/client/models/eventModel'

const { event, usePreviewMedias = true } = defineProps<{ event?: EventModel, usePreviewMedias?: boolean }>()

const eventStore = useEventStore()
const mediaStore = useMediaStore()

const { pending: pendingMedias } = !!event && !!event.medias && event.medias.length > 0
    ? await useLazyAsyncData("fetch-event-" + event.id + "-media-prefetch", async () => mediaStore.pushItemsInLocalCollection(await eventStore.fetchMediasAsync(event.id)), { server: false })
    : { pending: ref(false) }

const pending = computed(() => !!!event ? true : pendingMedias.value)

</script>

<style scoped>
.card {
    position: relative;
    padding: var(--m-medium);
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
}

.header-container {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: var(--m-small);
}

.header-info {
    display: grid;
    grid-template-rows: 1fr auto;
}

.header-info p,
.header-info h3 {
    font-size: var(--font-size-small);
    text-align: left;
}

a::after {
    position: absolute;
    content: '';
    inset: 0;
}

@media screen and (width >=1024px) {

    /* Makes the whole card clickable on desktop */
    a::after {
        z-index: 1;
    }

    /* Makes the carousel buttons work on top of the expanded card link on desktop */
    :deep(button) {
        z-index: 2;
    }
}
</style>
<template>
    <nav>
        <ul>
            <li v-for="page in pages" v-bind:key="page.url" v-bind:class="isPageActive(page.url) ? 'active' : ''">
                <NuxtLink v-bind:to="page.url" v-bind:aria-label="'Vai a ' + page.name">
                    <i v-bind:class="'fa fa-' + page.icon"></i>
                    <p class="page-name">{{ page.name }}</p>
                    <i class="fa fa-bicycle"></i>
                </NuxtLink>
            </li>
        </ul>
    </nav>
</template>

<script setup lang="ts">
import type { PageInfo } from '~/client/mainSitePages'

const route = useRoute()

const { pages } = defineProps<{
    pages: PageInfo[]
}>()

const isPageActive = (url: string) => url !== '/' ? route.path.startsWith(url) : route.path === url
</script>

<style scoped>
nav {
    --transition-duration: 250ms;

    background: linear-gradient(to bottom, var(--primary-500), var(--primary-400));
    padding-inline: var(--m-medium);
    position: fixed;
    bottom: 0;
    width: 100vw;
    z-index: 1;
}

ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    list-style-type: none;
    text-align: center;
}

li {
    background-color: transparent;
    transition: background-color var(--transition-duration) ease-in-out;
    overflow: hidden;
    color: var(--bg-light);
}

li.active {
    background-color: var(--secondary);
    border-radius: 0 0 100vw 100vw;
    color: var(--bg-dark);
    box-shadow: 0 0 16px var(--secondary);
}

i {
    display: block;
    margin: var(--m-half);
    transition: color var(--transition-duration) ease-in-out;
}

a {
    display: inline-block;
    height: 100%;
    font-size: var(--font-size-small);
    padding: var(--m-medium);
}

a,
a:hover,
a:visited {
    color: currentColor;
    text-decoration: underline transparent;
    transition: text-decoration-color 150ms ease-out;
}

a:hover {
    text-decoration-color: currentColor;
}

li i.fa-bicycle {
    text-align: center;
    visibility: hidden;
}

li.active i.fa-bicycle {
    animation: bicycle-active-enter var(--transition-duration) ease-out forwards;
    visibility: visible;
}

.page-name {
    display: none;
}

@keyframes bicycle-active-enter {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@media screen and (width >=768px) {
    nav {
        position: initial;
        margin-inline: var(--m-large);
        padding: var(--m-medium);
        border-radius: var(--m-large);
        width: initial;
    }

    li {
        flex-direction: row;
    }

    li i {
        margin: 0 var(--m-half);
        display: inline-block;
    }

    li.active {
        border-radius: var(--m-medium);
    }

    li:not(.active) i.fa-bicycle {
        display: none;
    }

    li.active .page-name {
        display: initial;
    }
}

@media screen and (width >=1024px) {
    a {
        font-size: var(--font-size-small);
        padding: var(--m-small-medium);
    }
}

@media screen and (width >=1440px) {
    nav {
        margin-inline: auto;
        max-width: calc(1440px - 2 * var(--m-large));
    }

    .page-name {
        display: initial;
    }
}
</style>
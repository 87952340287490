import revive_payload_client_G0n2Axd2dH from "C:/src/web/asdriccioneavis/node_modules/.pnpm/nuxt-nightly@4.0.0-28959005.6abefd3e_typescript@5.7.3_vite@6.0.11/node_modules/nuxt-nightly/dist/app/plugins/revive-payload.client.js";
import unhead_px1Bd1cEeZ from "C:/src/web/asdriccioneavis/node_modules/.pnpm/nuxt-nightly@4.0.0-28959005.6abefd3e_typescript@5.7.3_vite@6.0.11/node_modules/nuxt-nightly/dist/head/runtime/plugins/unhead.js";
import router_QdHDhRDtVb from "C:/src/web/asdriccioneavis/node_modules/.pnpm/nuxt-nightly@4.0.0-28959005.6abefd3e_typescript@5.7.3_vite@6.0.11/node_modules/nuxt-nightly/dist/pages/runtime/plugins/router.js";
import payload_client_xujhMo3WLC from "C:/src/web/asdriccioneavis/node_modules/.pnpm/nuxt-nightly@4.0.0-28959005.6abefd3e_typescript@5.7.3_vite@6.0.11/node_modules/nuxt-nightly/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vBQMYQXsaZ from "C:/src/web/asdriccioneavis/node_modules/.pnpm/nuxt-nightly@4.0.0-28959005.6abefd3e_typescript@5.7.3_vite@6.0.11/node_modules/nuxt-nightly/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_I1Byby3SI6 from "C:/src/web/asdriccioneavis/node_modules/.pnpm/nuxt-nightly@4.0.0-28959005.6abefd3e_typescript@5.7.3_vite@6.0.11/node_modules/nuxt-nightly/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_3iDDq4XQdV from "C:/src/web/asdriccioneavis/node_modules/.pnpm/nuxt-nightly@4.0.0-28959005.6abefd3e_typescript@5.7.3_vite@6.0.11/node_modules/nuxt-nightly/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_OjXqdJcUAc from "C:/src/web/asdriccioneavis/node_modules/.pnpm/@pinia+nuxt@0.5.5_typescript@5.7.3_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_zlvi6dcIsi from "C:/src/web/asdriccioneavis/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_tIxOgosXeY from "C:/src/web/asdriccioneavis/node_modules/.pnpm/nuxt-nightly@4.0.0-28959005.6abefd3e_typescript@5.7.3_vite@6.0.11/node_modules/nuxt-nightly/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_G0n2Axd2dH,
  unhead_px1Bd1cEeZ,
  router_QdHDhRDtVb,
  payload_client_xujhMo3WLC,
  navigation_repaint_client_vBQMYQXsaZ,
  check_outdated_build_client_I1Byby3SI6,
  chunk_reload_client_3iDDq4XQdV,
  plugin_vue3_OjXqdJcUAc,
  components_plugin_zlvi6dcIsi,
  prefetch_client_tIxOgosXeY
]
<template>
    <div class="page-container">
        <main>
            <h2>Prossimo evento</h2>
            <article v-if="!pending && !!events && events.incoming.length > 0">
                <EventCard v-bind:event="events.incoming.at(0)" v-bind:use-preview-medias="useIncomingEventsPreview" />
            </article>
            <p v-else>(Nessun prossimo evento)</p>
        </main>
        <hr />
        <aside>
            <h2>Ultimi eventi passati</h2>
            <ol v-if="pending">
                <li v-for="i in [1, 2]" v-bind:key="i">
                    <article>
                        <EventCard />
                    </article>
                </li>
            </ol>
            <ol v-else-if="!!events && events.past.length > 0">
                <li v-for="item in events.past" v-bind:key="item.id">
                    <article>
                        <EventCard v-bind:event="item" />
                    </article>
                </li>
            </ol>
            <p v-else>(Nessun evento passato)</p>
        </aside>
    </div>
</template>

<script setup lang="ts">
import { useMediaQuery } from '@vueuse/core';

const eventStore = useEventStore()
const notifySubscriber = useNotifySubscriberStore()
const { data: events, status } = await useAsyncData("fetch-home-events", async () => {
    const [incoming, past] = await Promise.all([
        eventStore.fetchFutureAsync(1),
        eventStore.fetchPastAsync(2),
    ])
    return { incoming, past }
})
const pending = computed(() => status.value === "pending")

const useIncomingEventsPreview = useMediaQuery("(width < 1024px)")

useHead({
    title: "A.S.D. Riccione AVIS",
})

definePageMeta({
    middleware: "clear-requests"
})

onMounted(async () => {
    if (!!navigator.serviceWorker) {
        const registration = await navigator.serviceWorker.register("sw.js")
        await notifySubscriber.refreshAsync(registration)
    }
})
</script>

<style scoped>
h2 {
    text-align: center;
    margin-bottom: var(--m-medium);
}

ol {
    list-style-type: none;
}

li:not(:last-child) {
    margin-bottom: var(--m-large);
}

main,
aside,
article {
    width: 100%;
}

main article,
aside article {
    margin: 0 auto;
}

main article {
    max-width: 800px;
}

aside article {
    max-width: 500px;
}

.page-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--m-large);
    margin: var(--m-large) auto;
    justify-items: center;
    width: min(90vw, 1280px);
}

hr {
    display: none
}

@media screen and (width >=1024px) {
    .page-container {
        grid-template-columns: 2fr auto 1fr;
    }

    hr {
        display: block;
        width: 2px;
        border-color: var(--grey-900);
    }
}
</style>
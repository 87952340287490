<template>
    <MainHeader />
    <NavBar v-bind:pages="mainSitePages" />
    <div>
        <slot></slot>
    </div>
    <button class="notify-fab" v-on:click="() => toggleNotifySubscriptionAsync()">
        <i v-bind:class="'fa fa-' + (notifySubscriber.isSubscribed ? 'bell-slash' : 'bell')"></i>
    </button>
    <MainFooter />
</template>

<script setup lang="ts">
import { mainSitePages } from '~/client/mainSitePages';

const notifySubscriber = useNotifySubscriberStore()

const toggleNotifySubscriptionAsync = async () => {
    if (notifySubscriber.isSubscribed) {
        await notifySubscriber.unsubscribeAsync()
    } else {
        await notifySubscriber.subscribeAsync()
    }
}
</script>

<style scoped>
div {
    padding-bottom: calc(4 * var(--m-large));
}

.notify-fab {
    position: absolute;
    right: 32px;
    bottom: 96px;
    width: 48px;
    aspect-ratio: 1/1;
    background-color: var(--primary-500);
    color: var(--grey-900);
    border-radius: 100vw;
    border: 0;
    font-size: var(--font-size-small);
}
</style>

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexYcLZbEFDb4Meta } from "C:/src/web/asdriccioneavis/pages/admin/events/index.vue?macro=true";
import { default as indexS5kQvREu7jMeta } from "C:/src/web/asdriccioneavis/pages/admin/index.vue?macro=true";
import { default as indexmME6qDT1DIMeta } from "C:/src/web/asdriccioneavis/pages/admin/login/index.vue?macro=true";
import { default as indextxQVK3bJ2qMeta } from "C:/src/web/asdriccioneavis/pages/admin/media-upload/index.vue?macro=true";
import { default as indexoTXz2oYfgtMeta } from "C:/src/web/asdriccioneavis/pages/admin/medias/index.vue?macro=true";
import { default as indexymd40SsIyZMeta } from "C:/src/web/asdriccioneavis/pages/admin/people/index.vue?macro=true";
import { default as indexzegSMsE268Meta } from "C:/src/web/asdriccioneavis/pages/admin/scores/index.vue?macro=true";
import { default as indexXdqmi3No2OMeta } from "C:/src/web/asdriccioneavis/pages/details/index.vue?macro=true";
import { default as indexobZvVjaF5FMeta } from "C:/src/web/asdriccioneavis/pages/events/[id]/index.vue?macro=true";
import { default as indexkCgGDDXhtdMeta } from "C:/src/web/asdriccioneavis/pages/events/index.vue?macro=true";
import { default as indexEBubvuyrMDMeta } from "C:/src/web/asdriccioneavis/pages/gallery/[id]/index.vue?macro=true";
import { default as index5ie5xsd1apMeta } from "C:/src/web/asdriccioneavis/pages/gallery/index.vue?macro=true";
import { default as index2Rma23eOWJMeta } from "C:/src/web/asdriccioneavis/pages/group/index.vue?macro=true";
import { default as index1rPyo1nBZfMeta } from "C:/src/web/asdriccioneavis/pages/index.vue?macro=true";
import { default as indexZXkl878tYpMeta } from "C:/src/web/asdriccioneavis/pages/scores/index.vue?macro=true";
export default [
  {
    name: "admin-events",
    path: "/admin/events",
    meta: indexYcLZbEFDb4Meta || {},
    component: () => import("C:/src/web/asdriccioneavis/pages/admin/events/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("C:/src/web/asdriccioneavis/pages/admin/index.vue")
  },
  {
    name: "admin-login",
    path: "/admin/login",
    meta: indexmME6qDT1DIMeta || {},
    component: () => import("C:/src/web/asdriccioneavis/pages/admin/login/index.vue")
  },
  {
    name: "admin-media-upload",
    path: "/admin/media-upload",
    meta: indextxQVK3bJ2qMeta || {},
    component: () => import("C:/src/web/asdriccioneavis/pages/admin/media-upload/index.vue")
  },
  {
    name: "admin-medias",
    path: "/admin/medias",
    meta: indexoTXz2oYfgtMeta || {},
    component: () => import("C:/src/web/asdriccioneavis/pages/admin/medias/index.vue")
  },
  {
    name: "admin-people",
    path: "/admin/people",
    meta: indexymd40SsIyZMeta || {},
    component: () => import("C:/src/web/asdriccioneavis/pages/admin/people/index.vue")
  },
  {
    name: "admin-scores",
    path: "/admin/scores",
    meta: indexzegSMsE268Meta || {},
    component: () => import("C:/src/web/asdriccioneavis/pages/admin/scores/index.vue")
  },
  {
    name: "details",
    path: "/details",
    component: () => import("C:/src/web/asdriccioneavis/pages/details/index.vue")
  },
  {
    name: "events-id",
    path: "/events/:id()",
    meta: indexobZvVjaF5FMeta || {},
    component: () => import("C:/src/web/asdriccioneavis/pages/events/[id]/index.vue")
  },
  {
    name: "events",
    path: "/events",
    meta: indexkCgGDDXhtdMeta || {},
    component: () => import("C:/src/web/asdriccioneavis/pages/events/index.vue")
  },
  {
    name: "gallery-id",
    path: "/gallery/:id()",
    meta: indexEBubvuyrMDMeta || {},
    component: () => import("C:/src/web/asdriccioneavis/pages/gallery/[id]/index.vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    meta: index5ie5xsd1apMeta || {},
    component: () => import("C:/src/web/asdriccioneavis/pages/gallery/index.vue")
  },
  {
    name: "group",
    path: "/group",
    meta: index2Rma23eOWJMeta || {},
    component: () => import("C:/src/web/asdriccioneavis/pages/group/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index1rPyo1nBZfMeta || {},
    component: () => import("C:/src/web/asdriccioneavis/pages/index.vue")
  },
  {
    name: "scores",
    path: "/scores",
    meta: indexZXkl878tYpMeta || {},
    component: () => import("C:/src/web/asdriccioneavis/pages/scores/index.vue")
  }
]